import React from "react";
import classnames from "classnames";

type Props = {
    children: JSX.Element;
    show: boolean;
    onClose: () => void;
};

const Modal = (props: Props) => {
    const classes = classnames("modal-bg", {
        showModal: props.show
    });

    return (
       <div className={classes}>
          <div className="modal-box">
             <div id="modal-content">{props.show ? props.children : null}</div>
             <div className="close-modal"><span onClick={props.onClose}>X</span> CLOSE</div>
          </div>
       </div>
    );
};

export default Modal;
