import * as React from "react";

const PerfectFor: React.FC = () => (
<section id="perfect-for" className="creambg">
      <h2>TacBoard is Perfect For</h2>
      <div className="perfect-for-container maxw1350">
         <div className="eventh">
            <img src="img/icon-per-for-weddings.svg" />
            <h3>Weddings</h3>
         </div>
         <div className="eventh">
            <img src="img/icon-per-for-corp.svg" />
            <h3>Corporate Events</h3>
         </div>
         <div className="eventh">
            <img src="img/icon-per-for-parties.svg" />
            <h3>Big Parties</h3>
         </div>
         <div className="eventh">
            <img src="img/icon-per-for-reunions.svg" />
            <h3>Reunions</h3>
         </div>
         <div className="eventh">
            <img src="img/icon-per-for-fundraisers.svg"/ >
            <h3>Fundraisers</h3>
         </div>
         <div className="eventh">
            <img src="img/icon-per-for-mitzvahs.svg" />
            <h3>Mitzvahs</h3>
         </div>
      </div>
      <div className="top-wave"></div>
      <div className="bottom-wave"></div>
   </section>
)

export default PerfectFor;
