import * as React from "react";

const SocialProof: React.FC = () => (
	<section id="social-proof">
      <h2>Join The Thousands Of Happy Clients</h2>
      <div className="reviews-container" id="reviews-container">
         <div className="inner-container" id="inner-container">
            <div className="review-item onGoogle">
               <p className="review">A HUGE hit with the conference attendees. It was great that they did not need to download an app or create an account to share their pictures. This
                  is one of the biggest advantages of using Tacboard for this purpose.</p>
               <div className="review-by-container">
                  <div className="review-by">Carrie S., Akron, OH</div>
               </div>
            </div>

            <div className="review-item onKnotReview">
               <p className="review">We set up a live screen and we loved seeing the photos our guests were sending. We had great feedback from our guests too who had a blast using it.
               </p>
               <div className="review-by-container">
                  <div className="review-by">Kim B., Pasadena, MD</div>
               </div>
            </div>

            <div className="review-item onGoogleReview">
               <p className="review">Guests loved being able to share their photos and feel involved!</p>
               <div className="review-by-container">
                  <div className="review-by">Emily P., Oak Point, TX</div>
               </div>
            </div>
            <div className="review-item onFacebook">
               <p className="review">Made my wedding guests feel like they were a part of everything...kept the energy up throughout the reception.</p>
               <div className="review-by-container">
                  <div className="review-by">Johnny W., San Rafael, CA</div>
               </div>
            </div>

            <div className="review-item onGoogleReview">
               <p className="review">One of the best decisions I made! My guests had so much fun with this, and we have so many great candid shots!</p>
               <div className="review-by-container">
                  <div className="review-by">Heather Christian</div>
               </div>
            </div>
            <div className="review-item onFacebook">
               <p className="review">
                  It is truly contagious. When your guests see their pictures on the screen it starts a chain of more to come. Everyone had a great time and we have lots of
                  pictures to last a lifetime. Thanks Tacboard!!!!!</p>
               <div className="review-by-container">
                  <div className="review-by">Michelle N</div>
               </div>
            </div>
         </div>
      </div>
      <div className="rev-btn-container">
         <button className="rev-btn" id="go-left">
            <div id="chevron-arrow-left"></div>
         </button>
         <button className="rev-btn" id="go-right">
            <div id="chevron-arrow-right"></div>
         </button>
      </div>
      <div className="all-reviews-conteiner">
         <a href="https://www.theknot.com/marketplace/tacboard-chicago-il-1023997">Read All Our Reviews on The Knot</a>
         <a href="https://goo.gl/jPkvrT">Read All Our Reviews on Google</a>
      </div>
      <div className="bottom-wave"></div>
   </section>
);

export default SocialProof;
