import styles from './HomeSection.module.scss';
import React, { useState } from 'react';
import Modal from '../Modal';
import { useRouter } from 'next/router';

const HomeSection = () => {
  const [show, setShow] = useState(false);
  const onOpen = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  const router = useRouter();
  const onSignUp = () => {
    router.push('/tacboard-pricing');
  };

  return (
    <>
      <div className={styles.signup} onClick={onSignUp}>
        <a href="/tacboard-pricing">Sign Up</a>
      </div>
      <section id="home-section">
        <div className="bgvid" id="bgvid">
          <video disableRemotePlayback autoPlay muted loop preload="auto">
            <source className="home-vid-src" src="/img/av-home-main-r8.webm" type="video/webm" />
            <source className="home-vid-src" src="/img/av-home-main-r8.mp4" type="video/mp4" />
          </video>
        </div>
        <h1 className="zup">Entertain your guests with a live photo stream while creating an album of candid photos</h1>
        <p className="zup">
          Collect forgotten old photos from friends and family before your event, then stream them at your event along with new photos shared by guests in real-time!
          <span className="just-text">
            No app. <i>Just texting.</i>
          </span>
        </p>

        <div className={styles.wrap}>
          <div onClick={onOpen} className="video open-modal zup" data-content-type="video" data-content="img/av-main-20sec">
            Watch
            <span>
              <div></div>
            </span>
          </div>
        </div>
        <div className="bottom-wave"></div>

        <Modal show={show} onClose={onClose}>
          <video disableRemotePlayback className="media" autoPlay controls>
            <source src="/img/av-main-20sec.webm" type="video/webm" />
            <source src="/img/av-main-20sec.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Modal>
      </section>
    </>
  );
};

export default HomeSection;
