import * as React from "react";

const WhatIsTacBoard: React.FC = () => (
	<section className="includes creambg" id="powered-by-text">
         <div className="flex-container">
            <div className="half">
               <span className="maxw80per">
                  <h2>What is TacBoard?</h2>
                  <p>TacBoard is a fun and interactive way to <span className="theme-primary bold">collect</span>,  <span className="theme-secondary bold">share</span> and <span className="theme-third bold">display</span> photos taken by guests at your event. It’s all done through text message making it effortless to use.
			  </p>
		   </span>
		</div>
		<div className="half">
		   <img src="img/t2s-np.png" />
		</div>
	 </div>
	 <div className="top-wave"></div>
	 <div className="bottom-wave"></div>
  </section>
)

export default WhatIsTacBoard;
