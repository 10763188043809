import * as React from "react";

const PoweredByText: React.FC = () => (
<section className="includes" id="what-is-tacboard">
      <div className="flex-container">
         <div className="half">
               <picture>
                     <source type="image/webp" srcSet="img/text01dx1.webp, img/text01dx1.webp 2x" media="(min-width: 801px)" />
                     <source type="image/webp" srcSet="img/text01mx1.webp, img/text01mx1.webp 2x" media="(max-width: 800px)" />
      
                     <source type="image/jpf" srcSet="img/text01dx1.jpf, img/text01dx1.jpf 2x" media="(min-width: 801px)" />
                     <source type="image/jpf" srcSet="img/text01mx1.jpf, img/text01mx1.jpf 2x" media="(max-width: 800px)" />
      
                     <source type="image/jpg" srcSet="img/text01dx1.jpg, img/text01dx2.jpg 2x" media="(min-width: 801px)" />
                     <source type="image/jpg" srcSet="img/text01mx1.jpg, img/text01mx2.jpg 2x" media="(max-width: 800px)" />
      
                     <img src="img/text01dx1.jpg" alt="TacBoard Gallery on all devices" />
                  </picture>
         </div>
         <div className="half">
            <span className="maxw80per">
               <h2>Powered by Text-Messaging</h2>
               <p>A unique phone number is created for your event.</p>
               <p><span className="theme-primary bold">Your guests text photos</span> to that number and those photos <span className="theme-secondary bold">instantly appear</span> in your online gallery AND your Live Photo Stream.</p>
               <p>Guests DO NOT have to download an app.</p>
            </span>
         </div>
      </div>
   </section>
)

export default PoweredByText;
