import * as React from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Header from '../components/Header';
import HomeSection from '../components/HomeSection';
import How from '../components/How';
import LivePhotoStream from '../components/LivePhotoStream';
import Package from '../components/Package';
import PerfectFor from '../components/PerfectFor';
import PoweredByText from '../components/PoweredByText';
import SocialProof from '../components/SocialProof';
import Statement from '../components/Statement';
import VendorBar from '../components/VendorBar';
import WhatIsTacBoard from '../components/WhatIsTacBoard';

export default function IndexRoute() {
  return (
    <>
      <Head>
        <title>TacBoard - Live Photo Stream For Your Event Space</title>
        <meta
          name="description"
          content="TacBoard is a Live Photo Stream for your event. Collect old photos from all your guests before your event and entertain guests while streaming live photos during your event."
        />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Aleo:200,300,400,700|Montserrat:300,400,500,700" />
        <link rel="canonical" href="https://tacboard.com" />
      </Head>

      <Layout classes={['venue-overview', 'public']}>
        <Header />
        <HomeSection />
        <Statement />
        <WhatIsTacBoard />
        <PoweredByText />
        <PerfectFor />
        <LivePhotoStream />
        <VendorBar />
        <How topWave={'home'} />
        <SocialProof />
        <Package />
        <Footer />
      </Layout>
    </>
  );
}
