import * as React from "react";

const Statement: React.FC = ({ children }) => (
	<section id="statment">
         <div className="statment-container">
            <div className="bg-color"></div>
            <div className="half">
               <div className="quote">
                  “I can’t imagine having a wedding and not using this. As the night went on, it got more and more
                  fun.”
                  <span className="quoted-by">Beth H.</span>
                  <span className="quoted-wedding">Bride</span>
                  <span className="quoted-location">Chicago, IL</span>
               </div>
            </div>
            <div className="half">
               <div className="img">
                  <span>
                     <picture>
                        <source srcSet="img/b1mx1.jpg, img/b1mx2.jpg 2x" media="(max-width: 800px)" />
                        <source srcSet="img/b1x1.jpg, img/b1x2.jpg 2x" media="(min-width: 801px)" />
                        <img src="img/b1x1.jpg" alt="TacBoard Gallery Image" />
                     </picture>
                  </span>
                  <span>
                     <picture>
                        <source srcSet="img/b2mx1.jpg, img/b2mx2.jpg 2x" media="(max-width: 800px)" />
                        <source srcSet="img/b2x1.jpg, img/b2x2.jpg 2x" media="(min-width: 801px)" />
                        <img src="img/b2x1.jpg" alt="TacBoard Gallery Image" />
                     </picture>
                  </span>
               </div>
            </div>
         </div>
      </section>
)

export default Statement;
