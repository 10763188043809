import * as React from 'react';

type Props = {
  topWave?: string;
};

const How = (props: Props) => (
  <section id="how" className="creambg">
    <h2>The Guest Experience</h2>
    <div className="advantages maxw1350">
      <div className="quarter advantage advantage1 open-modal">
        <picture>
          <source
            type="image/webp"
            srcSet="img/snap-it_400.webp, 
                     img/snap-it_600.webp 1.5x, 
                     img/snap-it_800.webp 2x"
          />
          <source
            type="image/jpg"
            srcSet="img/snap-it-400.jpg, 
                     img/snap-it-600.jpg 1.5x, 
                     img/snap-it-800.jpg 2x"
          />
          <img src="img/snap-it-400.jpg" alt="TacBoard Snap It Picture" />
        </picture>
        <span>
          <h4>Snap a Photo</h4>
          <p>A guest will snap a photo of the event on their phone.</p>
        </span>
      </div>
      <div className="quarter advantage advantage2 open-modal">
        <picture>
          <source
            type="image/webp"
            srcSet="img/text-it_400.webp, 
                     img/text-it_600.webp 1.5x, 
                     img/text-it_800.webp 2x"
          />
          <source
            type="image/jpg"
            srcSet="img/text-it-400.jpg, 
                     img/text-it-600.jpg 1.5x, 
                     img/text-it-800.jpg 2x"
          />
          <img src="img/text-it-400.jpg" alt="TacBoard Text It Picture" />
        </picture>
        <span>
          <h4>Text the Photo</h4>
          <p>That guest will text that photo to the unique phone number.</p>
        </span>
      </div>
      <div className="quarter advantage advantage4 open-modal" data-content-type="video" data-content="img/av-yours-forever">
        <video disableRemotePlayback className="thumb-vid" autoPlay muted loop style={{ borderRadius: '0' }}>
          <source src="img/av-see-it.webm" type="video/webm" />
          <source src="img/av-see-it.mp4" type="video/mp4" />
        </video>
        <span>
          <h4>See the Photo</h4>
          <p>Everyone will see the photo appear on the screen in real-time!</p>
        </span>
      </div>
    </div>
    {props.topWave === 'home' ? <div className="top-wave-gold"></div> : <div className="top-wave"></div>}
    <div className="bottom-wave-gray"></div>
  </section>
);

export default How;
