import * as React from "react";

const LivePhotoStream: React.FC = () => (
    <section className="live-photo-stream" id="live">
        <div className="flex-container row-reverse">
        <div className="half">
        <video className="thumb-vid" loop controls>
            <source src="img/av-main-20sec.webm" type="video/webm" />
            <source src="img/av-main-20sec.mp4" type="video/mp4" />
        </video>
        </div>
            <div className="half">
                <span className="maxw80per">
                    <h2>Live Photo Stream</h2>
                    <p>Easily connect a laptop to a TV or projector to stream your gallery live. Your event’s unique phone number appears at the top of the screen for quick and easy reference by your guests.</p>
                </span>
            </div>
        </div>
    </section>
);

export default LivePhotoStream;
