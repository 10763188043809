import React from "react";
import Link from "next/link";

const Package: React.FC = () => (
    <section id="package">
        <h3>Want TacBoard at Your Event?</h3>
        <Link href="/tacboard-pricing">
            <a className="get-tac-now">Sign Up!</a>
        </Link>
    </section>
);

export default Package;
